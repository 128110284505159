import React from "react";
import App from "next/app";
import {ThemeProvider, CSSReset, Flex} from "@chakra-ui/core";
import { appWithTranslation } from "next-i18next";

import ErrorScreen from "./_error";

import reporter from "~/reporting";

process.on("unhandledRejection", (error: Error) => {
  reporter.exception(error, {origin: "store | server | unhandledRejection"});
});

process.on("uncaughtException", (error: Error) => {
  reporter.exception(error, {origin: "store | server | uncaughtException"});
});

class Pency extends App {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    reporter.exception(error, {extras: errorInfo, origin: "store | client"});

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const {Component, pageProps} = this.props;
    const {statusCode: error, statusText} = pageProps;

    return (
      <ThemeProvider>
        <CSSReset />
        {error ? (
          <ErrorScreen statusCode={error} statusText={statusText} />
        ) : (
          <Flex direction="column" height="100%">
            <Component {...pageProps} />
          </Flex>
        )}
      </ThemeProvider>
    );
  }
}

export default appWithTranslation(Pency);
